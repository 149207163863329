<template>
  <div>
    <v-card :class="{ 'card-table_container--mobile' : $vuetify.breakpoint.xsOnly }">
      <v-data-table
        :headers="headers"
        :items="staff"
        :search="search"
        :server-items-length="pagination.itemsLength"
        :options.sync="pagination"
        :loading="loading"
        :footer-props="{'items-per-page-options':[10, 50, 100]}"
        @update:options="tablePaginated"
      >
        <template v-slot:item="{ item }">
          <tr
            v-if="$vuetify.breakpoint.smAndUp"
            @click="viewStaffDetail($event, item)">
            <td>{{ item.first_name }} {{ item.last_name }}</td>
            <td>{{ item.email }}</td>
            <td>
              <div class="text-right">
                <v-btn class="mx-2" dark small color="primary"> Edit </v-btn>
              </div>
            </td>
          </tr>
          <v-card
            v-else
            class="my-2 mx-1"
            @click="viewStaffDetail($event, item)"
          >
            <v-card-title class="pt-2">{{ item.first_name }} {{ item.last_name }}</v-card-title>
            <v-card-subtitle class="pb-2">{{ item.email }}</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-space-between">
              <v-btn class="mx-2" dark small color="primary"> Edit </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'StaffList',
    data() {
      return {
        search: '',
        pagination: {
          page: 1,
          itemsLength: 0,
          itemsPerPage: 10
        },
        loading: false,
        headers: [
          {text: 'Staff name', value: 'name', sortable: false},
          {text: 'Email', value: 'email', sortable: false},
          {text: '', value: 'action', sortable: false}
        ],
        staff: []
      }
    },
    methods: {
      ...mapActions(['getAllStaff']),
      getStaffList(params) {
        this.loading = true
        this.getAllStaff({
          limit: params.limit,
          offset: params.offset
        })
        .then((response) => {
          this.staff = response.data.results
          this.pagination.itemsLength = response.data.count
          this.loading = false
        }, (error) => {
          console.log('error', error)
        });
      },
      tablePaginated(event) {
        this.getStaffList({limit: event.itemsPerPage, offset: ((event.page - 1)*event.itemsPerPage)})
      },
      viewStaffDetail(event, item) {
        console.log('event', event)
        console.log('item', item)
        this.$router.push(`edit/${item.id}`)
      },
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/styles/variables.scss';

  ::v-deep {
    .v-data-table-header th {
      text-transform: uppercase;
    }
    tr {
      cursor: pointer;
    }
    tbody td a {
      color: inherit;
      &:hover {
        font-weight: bold;
        color: $primary-color;
      }
    }
    td {
      &:last-child {
        min-width: 160px;
      }
      min-width: 150px;
      @media only screen and (max-device-width: 768px) {
        padding: 0 8px!important
      }
    }
  }
</style>
